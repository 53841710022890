<div [ngClass]="{'container-scroller': ifAdmin}">
  <app-header *ngIf="ifAdmin"></app-header>
  <div  [ngClass]="{'container-fluid page-body-wrapper': ifAdmin}">
    <app-menu *ngIf="ifAdmin"></app-menu>
    <!-- partial -->
    <div [ngClass]="{'main-panel': ifAdmin}">
      <div   [ngClass]="{'content-wrapper': ifAdmin}">
        <router-outlet></router-outlet>
      </div>
      <!-- partial:partials/_footer.html -->
      <app-footer *ngIf="ifAdmin"></app-footer>
      <!-- partial -->
    </div>
    <!-- main-panel ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>

